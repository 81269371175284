import { executeGraphQLQueryFinex,executeGraphQLMutationFinex } from "../../common/executeGraphQLQuery";
import {STATUS_QUERY} from "../../service/leads.gql";
import { LEAD_DETAIL_QUERY,UPDATE_LEAD_STATUS } from "../../queries/sangam/leads.gql"
import { GET_MASTER_DATA } from "../../queries/sangam/masterdata.gql";
import "../../../src/saathi-web/saathi-web-style/mainlead.css";
import CibilScoreCheck from "../CibilScore/CibilScoreCheak";
import PartnerDocument from "../Document/PartnerDocument";
import BankOfferList from "../CibilScore/BankOfferList";
import PartnerShowDetails from "../PartnerShowDetails";
import EditLead from "../EditLeadComponent/EditLead";
import React, { useState, useEffect } from "react";
import OtherInformation from "../OtherInformation";
import FollowUpPage from "./FollowUpPage";
import Modal from "../../view/common/Modal";
import { Link , useParams, useHistory} from "react-router-dom";
import HistoryShow from "../HistoryShow";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import secureStorage from "../../config/encrypt";
import crypto from '../../config/crypto';
import Loader from '../../view/elements/PageLoader';
import { toast } from "react-toastify";
import LostModal from './LostModal';
import LoginDetails from "../StatusUpdate/LoginDetails";
import GeneralService from "../../service/generalService";
import { RoleAccess } from "../../config/constant";
import LoanOffer from "../my-tools/component/loanoffer/LoanOffer";
import BankCarouselList from "../BankCarouselList";
import LoanOfferDetails from "../CibilScore/LoanOfferDetails";
import { LoanDetailProvider } from "../../view/ruleEngine/component/loanDetailContext";
import { GET_LAST_CIBIL_INFO } from "../../queries/sangam/cibil.gql";
import { GET_LOAN_OFFER } from "../../queries/sangam/create.gql";


const SaathiPartnerDetails = (props) => {
  const mark_as_lost = RoleAccess("Mark As Lost Lead Listing")
  const move_to_login = RoleAccess("Move to login button")
  const cibil_check_leads = RoleAccess("Cibil Check My Leads ")
  const loan_offer_leads = RoleAccess("Loan Offer My Leads ")

  const Edit_lead = RoleAccess("Edit Lead")
  const [currentView, setCurrentView] = useState("details");
  const [editMode, setEditMode] = useState(false);
  const [isBankOffer, setIsBankOffer] = useState(false);
  const [openCibilPopup,setCibilPopup] = useState(false);
  const [leadDetail, setLeadData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const[masterdata,setMasterData] = useState([]);
  const [rejectionType,setRejection] = useState('');
  const [MarkAsLost, setMarkAsLost] = useState(false);
  const [reopenModal, setReopenModal] = useState(false);
  const [remarkText, setRemarkText] = useState("");
  const [financers, setFinancers] = useState([]);
  const [fulfillments, setFulfillment] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [subStatusList, setSubStatusList] = useState([]);
  const [assignTo, setAssignTo] = useState([]);
  const [checkOfferLoan, setCheckOfferLoan] = useState(false);
  const [cibilData , setCibilData] = useState({})
  const [loanOffers, setLoanOffers] = useState([]);
  
  const updateStateIntoRedux = props.updateStateIntoRedux;
  const params = useParams();
  let lead_id = crypto.decode(params.lead_id);
  let history =  useHistory();

  const getLoanOffers = async (lead_id) => {
    try {
      const response = await executeGraphQLQueryFinex(GET_LOAN_OFFER(lead_id));
      if (response.data.get_loan_offer) {
        setLoanOffers(response.data.get_loan_offer);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  useEffect(() => {
    const updateReduxData = async () => {
      const loginResponse = secureStorage.getItem("loginUserInfo");
      if (loginResponse) {
        updateStateIntoRedux("LOGIN_USER", loginResponse);
      }
    };
    updateReduxData();
    getLoanOffers(lead_id);
    
  }, [])

  const handleButtonClick = (view) => {
    setCurrentView(view);
  };

  const handleEditLead = () => {
    //setEditMode(true);
    let url =  `/lead-detail/${crypto.encode(lead_id)}`;
   // navigate(url)
   history.push(url)
  };

  const CibilScorePopup = () => {
    setCibilPopup(!openCibilPopup);
    if (!openCibilPopup) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  };

  

  const handleBankOfferComponent = () => {
    setIsBankOffer(true);
  }

  const handleModalVisiblity = () => {
    CibilScorePopup();
  }

  // getMasterData = () => {  
  //  executeGraphQLQueryFinex(GET_MASTER_DATA()).then((response) => {     
  //    setMasterData(response?.data?.masterdata)     
  //  })
  //    .catch((errors) => {
  //      toast.error("Somethig went wrong ,please try again")
  //    });
 // }

 const getMasterData = async () => {
  try {
    const response = await executeGraphQLQueryFinex(GET_MASTER_DATA());

    if (response && response.data && response.data.masterdata) {
      let result = { ...response.data.masterdata }; // Create a copy to avoid mutating the original response
      
      // Define keys that need processing
      let keyList = [
        'tenure', 'agreement_type', 'profession', 'salary_credit_mode',
        'property_type', 'usage_type', 'loan_type', 'loan_sub_type'
      ];

      // Process each key in keyList
      for (let item of keyList) {
        if (result[item] && result[item].length) {
          result[item] = result[item].map(data => ({
            ...data,
            value: +data.id, // Convert id to number
            label: data.label // Maintain the label
          }));
        }
      }

      // Process bank_list if it exists
      if (result.bank_list && result.bank_list.length) {
        result.bank_list = result.bank_list.map(data => ({
          ...data,
          value: data.id, // Use id as value
          label: data?.banklang?.bank_name || '' // Use bank name or empty string if not available
        }));
      }

      // Log the processed data for debugging
      // console.log("Processed data:", result);

      // Set the processed data in state
      setMasterData(result);
    }
  } catch (error) {
    // Handle any errors that occur during the query
    console.error("Error fetching master data:", error);
    toast.error("Something went wrong, please try again");
  }
};

  const getstatusList = () => {
    executeGraphQLQueryFinex(STATUS_QUERY).then((result) => {     
      setStatuses(result?.data?.status_list)
     // setSubStatusList(result?.data?.sub_status_list)
      //setFinancers(result.data?.financier_list)
     // setFulfillment(result.data?.fulfillment_type)
    })
      .catch((errors) => {
        toast.error("Somethig went wrong ,please try again")
      });
  }

  const leadDetails = async () => {
    setIsLoading(true);
    if(!lead_id) lead_id = 0;
    const result = await executeGraphQLQueryFinex(LEAD_DETAIL_QUERY(lead_id));
    if(result?.data?.get_lead_detail[0]){
      setLeadData(result?.data?.get_lead_detail[0]);
      getPreviousCibilData(result?.data?.get_lead_detail[0])
    }
    setIsLoading(false);
  };

  const getPreviousCibilData = async (leadDetail) => {
    //For fetching cibil data of generated cibil report
    try {
      const result = await executeGraphQLQueryFinex(
        GET_LAST_CIBIL_INFO(leadDetail?.customer?.pancard_no)
      );

      if (result?.data?.last_cibil_info) {
        setCibilData(result.data.last_cibil_info);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error fetching CIBIL data:", error);
    }
  };
  

  useEffect(() => {
    leadDetails();
    getMasterData();
    getstatusList();
  }, lead_id); 

  const handleStateUpdate = (isStateUpdate) => {
    if (isStateUpdate) {
      leadDetails();
      getMasterData();
      getstatusList();
    }
  }

  let loan_type = masterdata?.loan_type;  

  class NameFormatter {
    capitalizeFirstLetter(word) {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }
  
    capitalizeEachWord(name) {
        return name?.split(' ').map(this.capitalizeFirstLetter).join(' ');
    }
  
    formatFirstName(firstName) {
        return this.capitalizeEachWord(firstName);
    }
}
const nameFormatter = new NameFormatter();

const showModalMarkAsLost = (type) => { 
  //console.log(type);
  setRejection(type)
  setMarkAsLost(true); 
};
const hideModalMarkAsLost = () => { 
  setMarkAsLost(false);   
};
const showModalReopen = () => {
  setReopenModal(true) 
};

const hideModalReopen = () => {
  setRemarkText("");
  setReopenModal(false);  
};

const submitReopen = () => {
  if (!remarkText || /^\s*$/.test(remarkText)) {
      toast.error("Please enter remarks");
  }
  else {
      let mutation = UPDATE_LEAD_STATUS,
          variables = {
              api_called_by: 'saathi_web',
              partner_id: loginUserInfo?.dealer_id,
              UpdateLeadStatus: {
                  update_type: "reopen",
                  status_id: [],
                  lead_id: leadDetail.id,
                  user_id: 38,
                  // after_verified:10,
                  //changes
                  reopen_form: {
                      reopen_remark: remarkText
                  }
              }
          };
          executeGraphQLMutationFinex(mutation, variables)
          .then((resp) => {
              let response = resp?.data?.update_lead_status || null;
              if (response) {
                  toast.success("Success")
                  hideModalMarkAsLost();
//props.getLeadDetail(leadDetail.id);
                  setReopenModal(false)
              }
              else {
                  toast.error("Error");
              }
          })
          .catch((err) => {
              toast.error("Error")
          })
  }
}



const loginUserInfo = JSON.parse(localStorage.getItem("loginUserInfo"));

useEffect(() => {
  const fetchAssignToList = async () => {      
    try {
      const response = await GeneralService.subPartnerList(loginUserInfo.user_data.dealer_id);     
      if (response?.data?.status === 200) {               
        setAssignTo(response?.data?.data?.sub_partner_list || []);       
      }
    } catch (error) {
      console.error("Error fetching assignTo list:", error);
    }
  };
  fetchAssignToList();    
}, [loginUserInfo.user_data.dealer_id]);

const moveStatusLink = (lead_id,status_id) => {
  let url =  `/loggedin/${crypto.encode(lead_id)}`
  if(status_id == 1){
      url =  `/loggedin/${crypto.encode(lead_id)}`
  }else if(status_id == 2){
      url =  `/approved/${crypto.encode(lead_id)}`
  }else if(status_id ==3){
      url =  `/disbursed/${crypto.encode(lead_id)}` 
  }else if(status_id==4){
      url =  `/disbursed/${crypto.encode(lead_id)}`  
  }
  // window.open(url, '_blank');
  history.push(url);
  // navigate(url)
}

const handleCheckLoan = () => {
  setCheckOfferLoan(true);
}

const handleMYToolsVisiblity = (visiblity) => {
  setCheckOfferLoan(visiblity);
}


   const statusButtonText = leadDetail?.status_id === 1 ? 'Move To Login' : leadDetail?.status_id === 2 ? 'Move To Sanction' : (leadDetail?.status_id === 3 || leadDetail?.status_id === 4) ? 'Move To Disburse' : 'Move To Login';
   
  const path = history.location.pathname;
  let backTo = `/saathi-details-page/${params.lead_id}`;
  if (path.indexOf("saathi-details-page") !== -1) backTo = "/saathi-leads";
  const leadStatus = leadDetail?.status_info?.statuslang?.status_name === "LogIn" ? "Logged In" : leadDetail?.status_info?.statuslang?.status_name;

  return (
    <>
    {checkOfferLoan ? <LoanDetailProvider><LoanOffer setHome={handleMYToolsVisiblity} leadDetail={leadDetail} ></LoanOffer></LoanDetailProvider> : 
      <div className="detailstop">
        <div className="topcontainer">
          <div className="user_info">
           <Link to={backTo}>
           <div>
                {" "}
                 <img
                  src={require(`../../../src/saathi-web/images/left arrow.svg`)}
                  alt="not"
                /> 
            </div>
           </Link> 
            <div>
              <img
                src={require(`../../../src/saathi-web/images/user.svg`)}
                alt="not"
                width={72}
                height={72}
              />
            </div>
            <div className="nam_mob_mail">
              <div>{leadDetail?.customer?.first_name} {leadDetail?.customer?.last_name}</div>
              <div style={{marginBottom:8,marginTop:5}}>
                <img
                  src={require(`../../../src/saathi-web/images/mobile.svg`)}
                  alt="mobile"
                />
                {leadDetail?.customer?.mobile}
              </div>
              <div>
                <img
                  src={require(`../../../src/saathi-web/images/email.svg`)}
                  alt="email"
                />
                {leadDetail?.customer?.email || "--"}
              </div>
            </div>
          </div>
          <div className="loan_id_cont">
            <div>
              <button>{leadStatus}</button>
            </div>
            <div> Lead ID • <span>{leadDetail?.id}</span></div>
            <div>{loan_type?.filter(data => data.id == leadDetail?.loan_type)?.map(v => v.label)} {leadDetail?.lead_details?.bank?.banklang?.bank_name ? '•' : ''} <span> {nameFormatter.formatFirstName(leadDetail?.lead_details?.bank?.banklang?.bank_name)}</span></div>
          </div>
          <div className="move_login">
            <div>
            {
                            (leadDetail.rejection_type =='bank_hard_reject') ? 
                            <div className='d-flex' style={{marginLeft:"20px"}}>
                               <button className="lost-btn">Lead Closed</button>
                            </div>
                            :
                          mark_as_lost ===1 &&  <div className='d-flex' style={{marginLeft:"20px"}}>
                                {(leadDetail.status_id == 5 && leadDetail.rejection_type !='bank_hard_reject') ? <button className="markloatbrn" onClick={showModalReopen}>Reopen</button> :
                                    (leadDetail.status_id != 4) ? <button className="markloatbrn" onClick={()=>showModalMarkAsLost('lost')}>Mark as lost</button> : null
                                }
                            </div> 
                        } 
            </div>
            <div>
           { <LostModal MarkAsLost={MarkAsLost} hideModalMarkAsLost={hideModalMarkAsLost} rejection_type ={rejectionType} statusId={leadDetail.status_id} leadDetail={leadDetail} masterdata = {masterdata} masterStatusData = {statuses} />}
            <Modal isOpen={reopenModal} togglePopup={hideModalReopen} width={500} removeHeight={true}>
                            <h2>Re-open</h2>
                        <div>
                            <fieldset>
                                <div className="material">
                                    <textarea
                                        placeholder=" "
                                        className="form-input" rows="3"
                                        value={remarkText}
                                        onChange={(e) => setRemarkText(e.target.value)}>
                                    </textarea>
                                    <label
                                        data-label="Remarks"
                                        className="form-label"
                                    >
                                    </label>
                                </div>
                            </fieldset>
                            <button className="searchbutton" style={{marginTop:50}} onClick={submitReopen}>Save</button>
                        </div>
                    </Modal>           
             
                  <button
                    className="logintomovebtn" 
                    onClick={() => moveStatusLink(leadDetail?.id,leadDetail?.status_id)}
                  >
                    {statusButtonText}
                  </button>
             
             
             { /*<LoginDetails/> */}
            </div>
          </div>
        </div>

        {isBankOffer ? (
          <>
          <BankOfferList></BankOfferList>
          </>) : 
          <>
          {editMode ? (
          <EditLead leadDetail={leadDetail} masterdata = {masterdata} masterStatusData = {statuses} />
        ) : (
          <div className="lead_bodycontainer">
            {  <div className="leftcontainer">
              {leadDetail?.status_info?.statuslang?.status_name === "New Lead" && (loan_offer_leads === 1 || cibil_check_leads === 1 ) && <div className="lefttopcontainer">
            
              { cibil_check_leads === 1 && <Link to={`/my-tool-cibil/${crypto.encode(lead_id)}`}>
                <div className="lefttopcontainer_inner_left" onClick={CibilScorePopup}>
                 <div className="checkoffer">
                    <img
                      src={require(`../../../src/saathi-web/images/checkoffermeter.svg`)}
                      alt="checkoffer"
                    />
                    <div className="checkofferheading">
                      Check Customer Credit Score
                    </div>
                  </div>
                  
                  <div>
                    <img
                      src={require(`../../../src/saathi-web/images/rightarrownew.svg`)}
                      alt="checkoffer"
                      />
                  </div>
                </div>
                      </Link> }
                {loan_offer_leads === 1 && <div className="lefttopcontainer_inner_right">
                  <div className="checkoffer">
                    <img
                      src={require(`../../../src/saathi-web/images/percenthome.svg`)}
                      alt="checkoffer"
                    />
                    <div onClick={handleCheckLoan}>
                      <div className="checkofferheading">
                        Checkout Loan Offers!
                      </div>
                      <div>Explore tailored loan options</div>
                    </div>
                  </div>

                  <div>
                    <img
                      src={require(`../../../src/saathi-web/images/rightarrownew.svg`)}
                      alt="checkoffer"
                    />
                  </div>
                </div>}
              </div>}

              <div className="leftbottomcontainer">
                <div className="leftbottomcontainer1">
                  <div>
                    <button
                      className={currentView === "details" ? "active" : ""}
                      onClick={() => handleButtonClick("details")}
                    >
                      Details
                    </button>
                    <button
                      className={currentView === "documents" ? "active" : ""}
                      onClick={() => handleButtonClick("documents")}
                    >
                      Documents
                    </button>
                    {/* <button
                      className={currentView === "Payouts" ? "active" : ""}
                      onClick={() => handleButtonClick("Payouts")}
                    >
                      Payouts
                    </button> */}
                    <button
                      className={currentView === "History" ? "active" : ""}
                      onClick={() => handleButtonClick("History")}
                    >
                      History
                    </button>
                  </div>
                
                
             { Edit_lead === 1 && <button onClick={handleEditLead} className="editbtn"><i className="ic-createmode_editedit"></i></button>}
                </div>
                {(currentView === "details" && cibilData.data) || loanOffers.length > 0 ? (
              <div className="loanofferdetailsnew">
                  {currentView === "details" && cibilData.data && (
                   <div style={{ width: "49%" }}>
                  <LoanOfferDetails cibilData={cibilData} />
                 </div>
                   )}
                   {loanOffers.length > 0 && (
                   <div style={{ width: "49%" }}>
                 <BankCarouselList bankCarouselData={loanOffers} handleRecalculate={handleCheckLoan} />
                    </div>
                   )}
                 </div>
               ) : null}


                 { isLoading ? <Loader/> : ''}
              <div className="leftcontainerheight">
                {currentView === "details" && (
                  <>
                    <PartnerShowDetails leadDetail = {leadDetail} masterdata = {masterdata}/>
                  </>
                )}
                {currentView === "documents"  &&
                <PartnerDocument leadDetail={leadDetail} masterdata={masterdata} stateUpdate={handleStateUpdate} />
                }
                {currentView === "History"  &&
                <HistoryShow leadDetail = {leadDetail}/>
                }
              </div>
              </div>
            </div>}

            <div className="rightcontainer">
              <FollowUpPage leadDetail = {leadDetail }  />
              {/* <QuearyDetails/> */}
              <OtherInformation leadDetail = {leadDetail } assignTo = {assignTo} />
            </div>
          </div>
        )}
          </>
        }
          <Modal isOpen={openCibilPopup} togglePopup={CibilScorePopup} backdrop="static" >
          <CibilScoreCheck setModalVisiblity={handleModalVisiblity} onPress={handleBankOfferComponent}/>
        </Modal>
      </div>
    }
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth_token: state.authToken,
    user_loggedin: state.userLoggedIn,
    dealer_id: state.dealer_id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateStateIntoRedux: (actionType, stateData) =>
      dispatch({ type: actionType, componentState: stateData }),
  };
};

export default withTranslation("common")(
  connect(mapStateToProps, mapDispatchToProps)(SaathiPartnerDetails)
);

